import { createElementObject, createTileLayerComponent } from "@react-leaflet/core";
import { ControlPosition, GridLayer } from "leaflet";

import { labelRules, paintRules } from "protomaps-leaflet/src/default_style/style";
import { LayersControl, TileLayer } from "react-leaflet";
import { LeafletLayerOptions, leafletLayer } from "./leaflet-layer";

interface ProtoLeafletGridLayer extends GridLayer, LeafletLayerOptions {
  clearLayout: () => void;
  rerenderTiles: () => void;
}

const LeafletLayer = createTileLayerComponent<ProtoLeafletGridLayer, LeafletLayerOptions>(
  function createTileLayer(options: LeafletLayerOptions, context) {
    const layer = (leafletLayer as (options?: LeafletLayerOptions) => ProtoLeafletGridLayer)(options);
    return createElementObject(layer, context);
  },
  function updateTileLayer(layer, props, prevProps) {
    const { url, theme } = props;
    if ((url != null && url !== prevProps.url) || (theme != null && theme !== prevProps.theme)) {
      if (theme) {
        layer.paintRules = paintRules(theme);
        layer.labelRules = labelRules(theme);
        layer.backgroundColor = theme.background;
      }
      layer.clearLayout();
      layer.rerenderTiles();
    }
  },
);

const ProtoLayer = ({
  overlays,
  position,
  options,
}: {
  overlays?: ("Satellite" | "JAWG")[];
  position?: ControlPosition;
  options?: LeafletLayerOptions;
}) => {
  return (
    <LayersControl position={position}>
      <LayersControl.BaseLayer checked name="Carte">
        <LeafletLayer {...options} />
      </LayersControl.BaseLayer>
      {overlays?.map((overlay) => (
        <LayersControl.Overlay key={overlay} name={overlay}>
          {overlay == "Satellite" && (
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution={
                "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
              }
            />
          )}
          {overlay == "JAWG" && (
            <TileLayer
              url={`https://{s}.tile.jawg.io/jawg-${options && options.theme?.link == "#ffffff" ? "light" : "dark"}/{z}/{x}/{y}{r}.png?access-token=SHBWxtwi08qiHUY5DxGtYGKn9MQ2x4fkiT3BncFx82pxcOiyKzjkjKvBLUUPDyw8`}
              attribution={'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'}
            />
          )}
        </LayersControl.Overlay>
      ))}
    </LayersControl>
  );
};

export { ProtoLayer };
