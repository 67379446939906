import type { Bounds, Coords, GridLayerOptions, LatLng } from "leaflet";
import { DomUtil, GridLayer } from "leaflet";
import Point from "protomaps-leaflet/node_modules/@mapbox/point-geometry";

import { PMTiles } from "pmtiles";
import { labelRules, paintRules } from "protomaps-leaflet/src/default_style/style";
import { LabelRule, Labelers } from "protomaps-leaflet/src/labeler";
import { PaintRule, paint } from "protomaps-leaflet/src/painter";
import { PreparedTile, SourceOptions, View, sourcesToViews } from "protomaps-leaflet/src/view";
import { Theme } from "protomaps-leaflet/src/default_style/themes";
/*
export function maplibreThemeToProtomapsLeafletTheme(t: Theme): any {
    return {
      background: t.background,
      earth: t.earth,
      park_a: t.park_a,
      park_b: t.park_b,
      hospital: t.hospital,
      industrial: t.industrial,
      school: t.school,
      wood_a: t.wood_a,
      wood_b: t.wood_b,
      pedestrian: t.pedestrian,
      scrub_a: t.scrub_a,
      scrub_b: t.scrub_b,
      glacier: t.glacier,
      sand: t.sand,
      beach: t.beach,
      aerodrome: t.aerodrome,
      runway: t.runway,
      water: t.water,
      pier: t.pier,
      zoo: t.zoo,
      military: t.military,
  
      tunnel_other_casing: t.tunnel_other_casing,
      tunnel_minor_casing: t.tunnel_minor_casing,
      tunnel_link_casing: t.tunnel_link_casing,
      tunnel_medium_casing: t.tunnel_major_casing,
      tunnel_major_casing: t.tunnel_major_casing,
      tunnel_highway_casing: t.tunnel_highway_casing,
      tunnel_other: t.tunnel_other,
      tunnel_minor: t.tunnel_minor,
      tunnel_link: t.tunnel_link,
      tunnel_medium: t.tunnel_major,
      tunnel_major: t.tunnel_major,
      tunnel_highway: t.tunnel_highway,
  
      transit_pier: t.pier,
      buildings: t.buildings,
  
      minor_service_casing: t.minor_service_casing,
      minor_casing: t.minor_casing,
      link_casing: t.link_casing,
      medium_casing: t.major_casing_late,
      major_casing_late: t.major_casing_late,
      highway_casing_late: t.highway_casing_late,
      other: t.other,
      minor_service: t.minor_service,
      minor_a: t.minor_a,
      minor_b: t.minor_b,
      link: t.link,
      medium: t.major_casing_early,
      major_casing_early: t.major_casing_early,
      major: t.major,
      highway_casing_early: t.highway_casing_early,
      highway: t.highway,
  
      railway: t.railway,
      boundaries: t.boundaries,
      waterway_label: t.waterway_label,
  
      bridges_other_casing: t.bridges_other_casing,
      bridges_minor_casing: t.bridges_minor_casing,
      bridges_link_casing: t.bridges_link_casing,
      bridges_medium_casing: t.bridges_major_casing,
      bridges_major_casing: t.bridges_major_casing,
      bridges_highway_casing: t.bridges_highway_casing,
      bridges_other: t.bridges_other,
      bridges_minor: t.bridges_minor,
      bridges_link: t.bridges_link,
      bridges_medium: t.bridges_major,
      bridges_major: t.bridges_major,
      bridges_highway: t.bridges_highway,
  
      roads_label_minor: t.roads_label_minor,
      roads_label_minor_halo: t.roads_label_minor_halo,
      roads_label_major: t.roads_label_major,
      roads_label_major_halo: t.roads_label_major_halo,
      ocean_label: t.ocean_label,
      peak_label: t.peak_label,
      subplace_label: t.subplace_label,
      subplace_label_halo: t.subplace_label_halo,
      city_circle: t.ocean_label,
      city_circle_stroke: t.country_label,
      city_label: t.city_label,
      city_label_halo: t.city_label_halo,
      state_label: t.state_label,
      state_label_halo: t.state_label_halo,
      country_label: t.country_label,
    };
  }
*/
const timer = (duration: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
};

// replacement for Promise.allSettled (requires ES2020+)
// this is called for every tile render,
// so ensure font loading failure does not make map rendering fail
type Status = {
  status: string;
  value?: unknown;
  reason: Error;
};

const reflect = (promise: Promise<Status>) => {
  return promise.then(
    (v) => {
      return { status: "fulfilled", value: v };
    },
    (error) => {
      return { status: "rejected", reason: error };
    },
  );
};

type DoneCallback = (error?: Error, tile?: HTMLElement) => void;
type KeyedHtmlCanvasElement = HTMLCanvasElement & { key: string };

export interface LeafletLayerOptions extends GridLayerOptions {
  debug?: string;
  lang?: string;
  tileDelay?: number;
  language?: string[];
  paintRules?: PaintRule[];
  labelRules?: LabelRule[];
  tasks?: Promise<Status>[];
  maxDataZoom?: number;
  url?: PMTiles | string;
  sources?: Record<string, SourceOptions>;
  theme?: Theme;
  backgroundColor?: string;
}

export const leafletLayer = (options: LeafletLayerOptions = {}): unknown => {
  class LeafletLayer extends GridLayer {
    paintRules?: PaintRule[];
    labelRules?: LabelRule[];
    backgroundColor?: string;
    debug?: string;
    lang?: string;
    lastRequestedZ: number | undefined;
    tasks: Promise<Status>[];
    views: Map<string, View>;
    scratch: CanvasRenderingContext2D;
    labelers: Labelers;
    tileSize: number;
    tileDelay: number;
    onTilesInvalidated: (tiles: Set<string>) => void;
    _keyToTileCoords?: (key: string) => Coords;
    _pxBoundsToTileRange?: (bounds: Bounds) => Bounds;
    _getTiledPixelBounds?: (center: LatLng) => Bounds;
    xray: any;
    constructor(options: LeafletLayerOptions = {}) {
      if (options.noWrap && !options.bounds)
        options.bounds = [
          [-90, -180],
          [90, 180],
        ];
      if (options.attribution == null)
        options.attribution =
          '<a href="https://protomaps.com">Protomaps</a> © <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>';
      super(options);

      if (options.theme) {
        this.paintRules = paintRules(options.theme);
        this.labelRules = labelRules(options.theme);
        this.backgroundColor = options.theme.background;
      } else {
        this.paintRules = options.paintRules || [];
        this.labelRules = options.labelRules || [];
        this.backgroundColor = options.backgroundColor;
      }

      this.lastRequestedZ = undefined;
      this.tasks = options.tasks || [];

      this.views = sourcesToViews(options);

      this.debug = options.debug;
      const scratch = document.createElement("canvas").getContext("2d");
      this.scratch = scratch!;
      this.onTilesInvalidated = (tiles: Set<string>) => {
        for (const t of tiles) {
          this.rerenderTile(t);
        }
      };
      this.labelers = new Labelers(this.scratch, this.labelRules, 16, this.onTilesInvalidated);
      this.tileSize = 256 * window.devicePixelRatio;
      this.tileDelay = options.tileDelay || 3;
      this.lang = options.lang;
    }

    public async renderTile(
      coords: Coords,
      element: KeyedHtmlCanvasElement,
      key: string,
      done = () => {
        return;
      },
    ) {
      this.lastRequestedZ = coords.z;

      const promises = [];
      for (const [k, v] of this.views) {
        const promise = v.getDisplayTile(coords);
        promises.push({ key: k, promise: promise });
      }
      const tileResponses = await Promise.all(
        promises.map((o) => {
          return o.promise.then(
            (v: PreparedTile) => {
              return { status: "fulfilled", value: v, key: o.key, reason: undefined };
            },
            (error: Error) => {
              return { status: "rejected", reason: error, key: o.key, value: undefined };
            },
          );
        }),
      );

      const preparedTilemap = new Map<string, PreparedTile[]>();
      for (const tileResponse of tileResponses) {
        if (tileResponse.status === "fulfilled") {
          preparedTilemap.set(tileResponse.key, [tileResponse.value as PreparedTile]);
        } else {
          if (tileResponse.reason!.name === "AbortError") {
            // do nothing
          } else {
            console.error(tileResponse.reason);
          }
        }
      }

      if (element.key !== key) return;
      if (this.lastRequestedZ !== coords.z) return;

      await Promise.all(this.tasks.map(reflect));

      if (element.key !== key) return;
      if (this.lastRequestedZ !== coords.z) return;

      const layoutTime = this.labelers.add(coords.z, preparedTilemap);

      if (element.key !== key) return;
      if (this.lastRequestedZ !== coords.z) return;

      const labelData = this.labelers.getIndex(coords.z);

      if (!this._map) return; // the layer has been removed from the map

      const center = this._map.getCenter().wrap();
      const pixelBounds = this._getTiledPixelBounds!(center);
      const tileRange = this._pxBoundsToTileRange!(pixelBounds);
      const tileCenter = tileRange.getCenter();
      const priority = coords.distanceTo(tileCenter) * this.tileDelay;

      await timer(priority);

      if (element.key !== key) return;
      if (this.lastRequestedZ !== coords.z) return;

      const buf = 16;
      const bbox = {
        minX: 256 * coords.x - buf,
        minY: 256 * coords.y - buf,
        maxX: 256 * (coords.x + 1) + buf,
        maxY: 256 * (coords.y + 1) + buf,
      };
      const origin = new Point(256 * coords.x, 256 * coords.y);

      element.width = this.tileSize;
      element.height = this.tileSize;
      const ctx = element.getContext("2d");
      if (!ctx) {
        console.error("Failed to get Canvas context");
        return;
      }
      ctx.setTransform(this.tileSize / 256, 0, 0, this.tileSize / 256, 0, 0);
      ctx.clearRect(0, 0, 256, 256);

      if (this.backgroundColor) {
        ctx.save();
        ctx.fillStyle = this.backgroundColor;
        ctx.fillRect(0, 0, 256, 256);
        ctx.restore();
      }

      let paintingTime = 0;

      const paintRules = this.paintRules;

      paintingTime = paint(
        ctx,
        coords.z,
        preparedTilemap,
        this.xray ? null : labelData || null,
        paintRules!,
        bbox,
        origin,
        false,
        this.debug,
      );

      if (this.debug) {
        ctx.save();
        ctx.fillStyle = this.debug;
        ctx.font = "600 12px sans-serif";
        ctx.fillText(`${coords.z} ${coords.x} ${coords.y}`, 4, 14);

        ctx.font = "12px sans-serif";
        let ypos = 28;
        for (const [k, v] of preparedTilemap) {
          const dt = v[0].dataTile;
          ctx.fillText(`${k + (k ? " " : "") + dt.z} ${dt.x} ${dt.y}`, 4, ypos);
          ypos += 14;
        }

        ctx.font = "600 10px sans-serif";
        if (paintingTime > 8) {
          ctx.fillText(`${paintingTime.toFixed()} ms paint`, 4, ypos);
          ypos += 14;
        }

        if (layoutTime > 8) {
          ctx.fillText(`${layoutTime.toFixed()} ms layout`, 4, ypos);
        }
        ctx.strokeStyle = this.debug;

        ctx.lineWidth = 0.5;
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(0, 256);
        ctx.stroke();

        ctx.lineWidth = 0.5;
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(256, 0);
        ctx.stroke();

        ctx.restore();
      }
      done();
    }

    public rerenderTile(key: string) {
      for (const unwrappedK in this._tiles) {
        const wrappedCoord = this._wrapCoords(this._keyToTileCoords!(unwrappedK));
        if (key === this._tileCoordsToKey(wrappedCoord)) {
          this.renderTile(wrappedCoord, this._tiles[unwrappedK].el as KeyedHtmlCanvasElement, key);
        }
      }
    }

    public clearLayout() {
      this.labelers = new Labelers(this.scratch, this.labelRules!, 16, this.onTilesInvalidated);
    }

    public rerenderTiles() {
      for (const unwrappedK in this._tiles) {
        const wrappedCoord = this._wrapCoords(this._keyToTileCoords!(unwrappedK));
        const key = this._tileCoordsToKey(wrappedCoord);
        this.renderTile(wrappedCoord, this._tiles[unwrappedK].el as KeyedHtmlCanvasElement, key);
      }
    }

    public createTile(coords: Coords, showTile: DoneCallback) {
      const element = DomUtil.create("canvas", "leaflet-tile") as KeyedHtmlCanvasElement;
      element.lang = this.lang!;

      const key = this._tileCoordsToKey(coords);
      element.key = key;

      this.renderTile(coords, element, key, () => {
        showTile(undefined, element);
      });

      return element;
    }

    public _removeTile(key: string) {
      const tile = this._tiles[key];
      if (!tile) {
        return;
      }
      const el = tile.el as HTMLElement & {
        removed: boolean;
        key: string | undefined;
        width: number;
        height: number;
      };
      el.removed = true;
      el.key = undefined;
      DomUtil.removeClass(el, "leaflet-tile-loaded");
      el.width = el.height = 0;
      DomUtil.remove(tile.el);
      delete this._tiles[key];
      this.fire("tileunload", {
        tile: tile.el,
        coords: this._keyToTileCoords!(key),
      });
    }
  }
  return new LeafletLayer(options);
};
