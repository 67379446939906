// eslint-disable-next-line prettier/prettier
import "leaflet.markercluster";
// eslint-disable-next-line prettier/prettier
import { LayerGroup, LeafletMouseEventHandlerFn, MarkerClusterGroup as MCG, Marker, MarkerClusterGroupOptions, layerGroup, markerClusterGroup } from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster/dist/MarkerCluster.css";

import { ReactNode } from "preact/compat";
import { useEffect } from "preact/hooks";
import {
    LeafletContextInterface,
    createElementObject,
    createPathComponent, extendContext
} from "@react-leaflet/core";
import { useMap } from "react-leaflet";
type ClusterType = { [key in string]: any };

type ClusterEvents = {
  onClick?: LeafletMouseEventHandlerFn;
  onDblClick?: LeafletMouseEventHandlerFn;
  onMouseDown?: LeafletMouseEventHandlerFn;
  onMouseUp?: LeafletMouseEventHandlerFn;
  onMouseOver?: LeafletMouseEventHandlerFn;
  onMouseOut?: LeafletMouseEventHandlerFn;
  onContextMenu?: LeafletMouseEventHandlerFn;
};

type MarkerClusterControl = MarkerClusterGroupOptions & {
  children: ReactNode;
} & ClusterEvents;

function getPropsAndEvents(props: MarkerClusterControl) {
  let clusterProps: ClusterType = {};
  let clusterEvents: ClusterType = {};
  const { ...rest } = props;
  // Splitting props and events to different objects
  Object.entries(rest).forEach(([propName, prop]) => {
    if (propName.startsWith("on")) {
      clusterEvents = { ...clusterEvents, [propName]: prop };
    } else {
      clusterProps = { ...clusterProps, [propName]: prop };
    }
  });
  return { clusterProps, clusterEvents };
}

function createMarkerClusterGroup(props: MarkerClusterControl, context: LeafletContextInterface) {
  const { clusterProps, clusterEvents } = getPropsAndEvents(props);
  const markerClusterGroup = new MCG(clusterProps);
  Object.entries(clusterEvents).forEach(([eventAsProp, callback]) => {
    const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`;
    markerClusterGroup.on(clusterEvent, callback);
  });
  return createElementObject(markerClusterGroup, extendContext(context, { layerContainer: markerClusterGroup }));
}

const MarkerClusterGroup = createPathComponent<MCG, MarkerClusterControl>(createMarkerClusterGroup);

const CustomMarkerCluster = ({
  markers,
  options,
  disable,
}: {
  markers: Marker[];
  options?: MarkerClusterGroupOptions;
  disable?: boolean;
}) => {
  const map = useMap();

  useEffect(() => {
    let cluster: LayerGroup;
    if (!disable) {
      cluster = markerClusterGroup(options);
      markers && markers.forEach((marker) => marker.addTo(cluster));
    } else {
      cluster = layerGroup(markers);
    }
    map.addLayer(cluster);
    return () => {
      cluster.clearLayers();
      map.removeLayer(cluster);
    };
  }, [markers, map]);

  return null;
};

export { CustomMarkerCluster, MarkerClusterGroup };
